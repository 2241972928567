import { Box, BoxProps, CircularProgress } from '@mui/material'

const InPageLoader = ({ sx, ...boxProps }: BoxProps) => (
  <Box
    sx={{
      mt: 10,
      display: 'flex',
      justifyContent: 'center',
      ...sx
    }}
    {...boxProps}
  >
    <CircularProgress size={'3em'}/>
  </Box>
)

export default InPageLoader
